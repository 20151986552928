const sidebarMenu = [
  {
    path: "/userList",
    name: "회원 관리",
    icon: "pe-7s-user-female",
    layout: "/admin",
  },
  {
    path: "/userDropList",
    name: "탈퇴 관리",
    icon: "pe-7s-user-female",
    layout: "/admin",
  },
  {
    path: "/noticeList",
    name: "공지사항 관리",
    icon: "pe-7s-bell",
    layout: "/admin",
  },
  {
    path: "/faqList",
    name: "FAQ 관리",
    icon: "pe-7s-help1",
    layout: "/admin",
  },
  {
    path: "/accountReady",
    name: "정산 예정",
    icon: "pe-7s-help1",
    layout: "/admin",
  },
  {
    path: "/accountComplete",
    name: "정산 완료",
    icon: "pe-7s-help1",
    layout: "/admin",
  },
  {
    path: "/sendReady",
    name: "송금 예정",
    icon: "pe-7s-help1",
    layout: "/admin",
  },
  {
    path: "/sendComplete",
    name: "송금 완료",
    icon: "pe-7s-help1",
    layout: "/admin",
  },
  {
    path: "/brandList",
    name: "브랜드관리",
    icon: "pe-7s-help1",
    layout: "/admin",
  },
  // {
  //   path: "/companyInfo",
  //   name: "회사정보",
  //   icon: "pe-7s-info",
  //   layout: "/admin",
  // },
];

export default sidebarMenu;
