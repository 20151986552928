import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Modal,
  Table,
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { useHistory } from "react-router-dom";
// import { UserCard } from "views/users/node_modules/components/UserCard/UserCard.jsx.js";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  fetchData,
  editFormData,
  getData,
  addFormData,
  addData,
  deleteData,
} from "modules/api.js";
import { useStyles } from "./style.jsx";
import Moment from "moment";


function UserDetail(props) {
  const [input, setInput] = useState();
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState();
  const [sum, setSum] = useState();
  const [addressModalShow, setAddressModalShow] = useState(false);
  const [exkrw, setexkrw] = useState(0);
  const [ethusdc, setEthusdc] = useState(0);
  const reload = () => {
    (async function anyNameFunction() {
      const result = await fetchData(`admin/user/${props.match.params.value}`);
      console.log(result);
      
      setInput(result.data);
      
    })();
  };

  const handleInputChange = (e) => {
    // console.log(e.currentTarget.value)
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const changeProfileImage = (e) => {
    let reader = new FileReader();
    let imageFile = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        files: imageFile,
        profileImg: reader.result,
      });
    };
    reader.readAsDataURL(imageFile);
  };

  // const handleComplete = (postCodeData) => {
  //   //주소변경
  //   console.log(postCodeData);

  //   (async function anyNameFunction() {
  //     const res = await fetchData(
  //       `api/getPosition?address=${postCodeData.address}`
  //     );
  //     console.log(res);

  //     if (res.result && res.data) {
  //       setInput({
  //         ...input,
  //         address: postCodeData.address,
  //         latitude: res.data.latitude,
  //         longitude: res.data.longitude,
  //       });
  //     } else {
  //       setInput({
  //         ...input,
  //         address: postCodeData.address,
  //       });
  //     }
  //   })();

  //   setAddressModalShow(false);
  // };

  useEffect(() => {
    // const auth = localStorage.getItem("auth") || "";
    // if (auth === "" || auth === undefined || auth === null) {
    //   history.push("/login");
    //   // return false;
    // }
    reload();
  }, []);

  const onSubmit = () => {
    // console.log(input.joinConfirm);
    if (window.confirm("수정하시겠습니까?")) {
      
      const body = {
        userId: props.match.params.value,
        status: input.joinConfirm
      }
      // console.log("body1",body);
      editFormData(`admin/joinConfirm`, body).then(function (
        response
      ) {
        console.log(response);
        if (response.result) {
          alert("회원정보 수정이 완료되었습니다.");
          history.push(`/admin/userList/1`);
        } else {
          // alert(response);
        }
      });
    }
  };

  const onRemove = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteData(`admin/user`, input.id).then(function (response) {
        if (response.result) {
          alert("삭제성공");
          history.push(`/admin/userList/1`);
        } else {
          alert("삭제실패");
          return false;
        }
      });
    }
  };

  return (
    <div className="content">
      <Grid fluid>
        {input && (
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Col md={12}>
              <Card
                title="회원 상세정보"
                content={
                  <form onSubmit={onSubmit}>
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {
                          label: "번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "id",
                          disabled: true,
                          value: input.id || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "이름",
                          type: "text",
                          bsClass: "form-control",
                          name: "name",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.name,
                          style: { cursor: "initial" },
                        },

                        {
                          label: "이메일",
                          type: "text",
                          bsClass: "form-control",
                          name: "email",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.email,
                          style: { cursor: "initial" },
                        },
                        
                      ]}
                    />

                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {
                          label: "핸드폰번호",
                          type: "text",
                          bsClass: "form-control",
                          name: "phone",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.phone,
                          style: { cursor: "initial" },
                        },

                        {
                          label: "인스타그램",
                          type: "text",
                          bsClass: "form-control",
                          name: "instagram",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.instagram || "",
                          style: { cursor: "initial" },
                        },
                        {
                          label: "가입일",
                          type: "text",
                          bsClass: "form-control",
                          name: "createdAt",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.createdAt
                            ? Moment(input.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )
                            : "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4",  "col-md-4"]}
                      properties={[
                        {
                          label: "닉네임",
                          type: "text",
                          bsClass: "form-control",
                          name: "nickname",
                          disabled: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.nickname,
                          style: { cursor: "initial" },
                        },

                        {
                          label: "등급",
                          type: "text",
                          bsClass: "form-control",
                          name: "임시 비밀번호",
                          disabled: true,
                          // onChange: (e) => handleInputChange(e),
                          // value: input.instagram || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "자기소개",
                          type: "text",
                          bsClass: "form-control",
                          name: "introduction",
                          disabled: true,
                          value: input.introduction,
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    
                    
                    <Button
                      bsStyle="warning"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onRemove}
                    >
                      삭제
                    </Button>
                    <Button
                      bsStyle="warning"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={onSubmit}
                    >
                      임시 비번 설정
                    </Button>
                    <Button
                      bsStyle="success"
                      style={{ marginLeft: "1%" }}
                      pullRight
                      fill
                      onClick={(e) => history.goBack()}
                    >
                      목록
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        )}
      </Grid>

      {/* <Modal
        show={addressModalShow}
        onHide={() => setAddressModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered="true"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" />
        </Modal.Header>
        <Modal.Body>
          <DaumPostcode onComplete={handleComplete} key="daum" />
        </Modal.Body>
      </Modal> */}
    </div>
  );
}

export default UserDetail;
