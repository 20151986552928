import React, { useState, useEffect } from "react";
import { fetchData } from "modules/api.js";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import Pagination from "@material-ui/lab/Pagination";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
  style,
  buttonDivStyle,
  buttonStyle1,
  BootstrapInput,
  useStyles,
} from "./style.jsx";
import { FullscreenExit } from "@material-ui/icons";

function UserList(props) {
  const userThArray = [
    "번호",
    "이름",
    "닉네임",
    "이메일",
    "휴대폰번호",
    "가입일",
    "인스타그램 주소",
  ];
  const classes = useStyles();
  const [dataList, setDataList] = useState();
  const [total, setTotal] = useState(1);
  const [newTotal, setNewTotal] = useState(0);
  const [dropTotal, setDropTotal] = useState(0);
  const [allTotal, setAllTotal] = useState(0);
  const history = useHistory();
  const [searchData, setSearchData] = useState({
    searchType: "",
    searchWord: "",
    status: "",
    startDate: "",
    endDate: ""
  });
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState();

  const reload = (value) => {
    if (value === undefined || value === null) {
      value = page;
    }

    (async function anyNameFunction() {
      const result = await fetchData(
        `admin/userList?page=${value}&searchWord=${searchData.searchWord}&searchType=${searchData.searchType}&startDate=${searchData.startDate && Moment(searchData.startDate).format("YYYY-MM-DD")}&endDate=${searchData.endDate && Moment(searchData.endDate).add(1, 'days').format("YYYY-MM-DD")}`
        // `admin/userList?page=${value}&level=user`
        // `admin/userList?page=${value}&level=user&searchWord=${searchData.searchWord}`
      );
      console.log(result);
      if (!result.result) {
        localStorage.removeItem("auth");
        history.push("/login");
      }
      setTotalItem(result.data.data.count);
      setTotal(Math.ceil(result.data.data.count / 10));
      // setDataList(result.data);
      console.log(result.data);
      setDataList(result.data.data.rows);
      setNewTotal(result.data.datshboardNew.total);
      setDropTotal(result.data.datshboardDrop.total);
      setAllTotal(result.data.dashBoardTotal.total);
    })();
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
    }
    console.log("재로딩");
    setPage(Number(props.match.params.value));
    reload(Number(props.match.params.value));
  }, []);

  const pageChange = (event, value) => {
    setPage(value);
    reload(value);
    history.push(`/admin/userList/${value}`);
  };

  const searchChange = (event) => {
    if (window.event.keyCode === 13) {
      reload(1);
    }
    setSearchData({
      ...searchData,
      [event.target.name]: event.target.value,
    });
  };

  const onSearch = () => {
    setPage(1);
    reload(1);
  };
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={4}>
            <Card
              content={
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p>신규회원</p>
                  <div>{newTotal}</div>
                </div>
                
              }
            />
          </Col>
          <Col md={4}>
            <Card
              content={
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p>탈퇴회원</p>
                  <div>{dropTotal}</div>
                </div>
              }
            />
          </Col>
          <Col md={4}>
            <Card
              content={
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <p>Total</p>
                  <div>{allTotal}</div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="회원 검색"
              ctTableFullWidth
              ctTableResponsive
              content={
                <div>
                  <ul style={{display:'flex', listStyle:'none', alignItems:'center'}}>
                    <li style={{width:'80px'}}>개인정보</li>
                    <li style={{marginLeft:'20px'}}>
                      <select name="searchType" style={{height: '40px', width:'150px', marginRight:'10px'}} onChange={(e) => setSearchData({...searchData, searchType: e.target.value})}>
                        <option value="">전체</option>
                        <option value="name">이름</option>
                        <option value="nickname">닉네임</option>
                      </select>
                      <BootstrapInput
                        id="demo-customized-textbox"
                        name="searchWord"
                        onChange={(e) => searchChange(e)}
                        value={searchData.searchWord || ""}
                        onKeyUp={(e) => searchChange(e)}
                      />
                    </li>
                  </ul>
                  {/* <ul style={{display:'flex', listStyle:'none', alignItems:'center'}}>
                    <li>회원등급</li>
                    <li style={{marginLeft:'20px'}}>
                      <select name="">

                      </select>
                    </li>
                  </ul> */}
                  <ul style={{display:'flex', listStyle:'none', alignItems:'center', }}>
                    <li style={{width:'80px'}}>가입일</li>
                    <li style={{marginLeft:'20px', display:'flex'}}>
                      <div style={{marginRight:'5px'}}><DatePicker dateFormat="yyyy-MM-dd" selected={searchData.startDate} onChange={(date) => setSearchData({...searchData, startDate: date})} style={{height:'40px'}} /></div> 
                      ~
                      <div style={{marginLeft:'5px'}}><DatePicker dateFormat="yyyy-MM-dd" selected={searchData.endDate} onChange={(date) => setSearchData({...searchData, endDate: date})} style={{height:'40px'}} /></div>
                    </li>
                  </ul>
                  <div style={{textAlign:'center', marginTop:'10px'}}>
                    <Button variant="outlined" onClick={(e) => onSearch(e)}>검색</Button>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="회원 관리"
              ctTableFullWidth
              ctTableResponsive
              content={
                <div>
                  {/* <div style={buttonDivStyle}>
                    <FormControl className={classes.margin}>
                      <BootstrapInput
                        id="demo-customized-textbox"
                        name="searchWord"
                        onChange={(e) => searchChange(e)}
                        value={searchData.searchWord || ""}
                        onKeyUp={(e) => searchChange(e)}
                      />
                    </FormControl>
                    
                    <Button
                      variant="outlined"
                      style={buttonStyle1}
                      onClick={(e) => onSearch(e)}
                    >
                      검색
                    </Button>
                  </div> */}
                  <Table striped hover>
                    <thead>
                      <tr>
                        {userThArray.map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {dataList && dataList.length > 0 ? (
                        dataList.map((data, index) => {
                          // data.no = totalItem - (page - 1) * 20 - index;
                          // console.log(dataList.length);
                          return (
                            <tr
                              key={index}
                              onClick={(e) =>
                                history.push(`/admin/user/${data.id}`)
                              }
                            >
                              <td>{data.id}</td>
                              <td>{data.name && data.name !== "null" ? data.name:''}</td>
                              <td>{data.nickname}</td>
                              <td>{data.email}</td>
                              <td>
                                {data.phone != "null" &&
                                  data.phone != "undefined" &&
                                  data.phone}
                              </td>
                              <td>
                                {(data.createdAt &&
                                  Moment(data.createdAt).format(
                                    "YYYY-MM-DD"
                                  )) ||
                                  ""}
                              </td>
                              <td>{data.instagram && data.instagram !== "null" && data.instagram !== 'undefined' ? data.instagram : ''}</td>
                              {/* <td>
                                {!data.joinConfirm && '가입신청'}
                                {data.joinConfirm === 'R' && '검토중'}
                                {data.joinConfirm === 'Y' && '승인'}
                                {data.joinConfirm === 'N' && '거절'}
                              </td> */}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="8" style={{ textAlign: "center" }}>
                            검색결과가 없습니다
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              }
            />
            <Pagination
              count={total}
              page={page}
              size="large"
              onChange={pageChange}
              variant="outlined"
              shape="rounded"
              style={style}
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default UserList;
