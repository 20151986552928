import React, { useState, useEffect } from "react";
import { getLogin } from "modules/api";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "draft-js-image-plugin/lib/plugin.css";
import "./global";
import "bootstrap/js/modal";
import "bootstrap/js/dropdown";
import "bootstrap/js/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import AdminLayout from "layouts/Admin.jsx";
import SignInSide from "views/SignIn.jsx";

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth != "") {
      setAuthenticated(true);
    }
  }, []);

  // 로그인 함수
  const onSubmitLogin = (params) => {
    localStorage.removeItem("auth");
    console.log(params);

    (async function loginFun() {
      const loginInfo = await getLogin(params);
      console.log("loginInfo", loginInfo);
      // return false;
      if (loginInfo && loginInfo.result) {
        localStorage.setItem("auth", "success");
        localStorage.setItem("token", loginInfo.token);
        setAuthenticated(true);
        history.push("/admin/userList/1");
      } else {
        alert("아이디/비밀번호를 다시 확인해주세요");
        return false;
      }
    })();
  };

  const onLogout = () => {
    localStorage.removeItem("auth");
    setAuthenticated(false);
    history.push("/login");
  };

  return (
    <div className="Wrapper" style={{ backgroundColor: "#ffffff" }}>
      <Switch>
        <Route
          path="/admin"
          render={(props) => <AdminLayout onLogout={onLogout} {...props} />}
        />
        <Route
          path="/login"
          render={(props) => (
            <SignInSide onSubmitLogin={onSubmitLogin} {...props} />
          )}
        />
        {!authenticated && <Redirect from="/" to="/login" />}
        {authenticated && <Redirect from="/" to="/admin" />}
      </Switch>
    </div>
  );
}

export default App;
