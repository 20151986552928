import React, { useState, useEffect } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import {
  addFormData,
  editFormData,
  addJsonData,
  addFileUpload,
  fetchData,
  deleteData,
} from "modules/api.js";
import Moment from "moment";
import "draft-js-image-plugin/lib/plugin.css";
import "../../global";
import "bootstrap/js/modal";
import "bootstrap/js/dropdown";
import "bootstrap/js/tooltip";
import "bootstrap/dist/css/bootstrap.css";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "react-summernote/lang/summernote-ko-KR";

function NoticeDetail(props) {
  const detailType = {
    id: "",
    title: "",
  };
  const [input, setInput] = useState(detailType);
  const [data, setData] = useState();

  const [editorText, setEditorText] = useState("");

  const history = useHistory();

  const reload = async (param) => {
    //데이터 로딩
    if (props.match.params.value !== "add") {
      const result = await fetchData(`user/notice/${param}`);
      console.log(result);
      setInput(result.data);
      setData(result.data);
      setEditorText(result.data.contents);
    }
  };

  const handleInputChange = (e) => {
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const changeProfileImage = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setInput({
        ...input,
        file: file,
        image: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
    }
    reload(Number(props.match.params.value));
  }, []);

  const onSubmit = () => {
    if (!input.title || input.title == "") {
      alert("제목을 입력해주세요");
      return false;
    } else if (input.title.length > 100) {
      alert("제목은 100자 이하로 작성해주세요");
      return false;
    }
    if (!editorText) {
      alert("내용을 입력해주세요");
      return false;
    }

    // const bodyFormData = new FormData();
    // // bodyFormData.set("id", input.id);
    // bodyFormData.set("title", input.title);
    // bodyFormData.set("contents", editorText);

    const bodyFormData = { title: input.title, contents: editorText };

    editFormData(`admin/notice/${input.id}`, bodyFormData).then(function (response) {
      console.log(response);
      if (response.result) {
        alert("공지사항 수정이 완료되었습니다.");
        history.push(`/admin/noticeList/1`);
      } else {
        alert(response.msg);
      }
    });
  };

  const onAdd = (e) => {
    if (!input.title || input.title == "") {
      alert("제목을 입력해주세요");
      return false;
    } else if (input.title.length > 100) {
      alert("제목은 100자 이하로 작성해주세요");
      return false;
    }
    if (!editorText) {
      alert("내용을 입력해주세요");
      return false;
    }
    const body = { title: input.title, contents: editorText };

    addFormData(`admin/notice`, body).then(function (response) {
      console.log(response);
      if (response.result) {
        alert("공지사항 정보 등록이 완료되었습니다.");
        history.push(`/admin/noticeList/1`);
      } else {
        alert(response);
      }
    });
  };

  const onRemove = () => {
    if (window.confirm("삭제하시겠습니까?")) {
      deleteData(`admin/notice`, input.id).then(function (response) {
        console.log(response);
        if (response.result) {
          alert("삭제성공");
          history.push(`/admin/noticeList/1`);
        } else {
          alert("삭제실패");
        }
      });
    }
  };

  /**
   *
   * 서머노트 관련 함수
   */
  const onChange = (content) => {
    setEditorText(content);
  };

  const onImageUpload = (images, insertImage) => {
    console.log("onImageUpload", images);
    const bodyFormData = new FormData();
    for (let i = 0; i < images.length; i++) {
      bodyFormData.set("files", images[i]);
      addFileUpload(`admin/image-insert`, bodyFormData).then(function (
        response
      ) {
        console.log(response.data.filePath);
        insertImage(response.data.filePath);
      });
    }
  };

  const onInit = (note) => {
    note.reset();
    const regex = /(\<\w*)((\s\/\>)|(.*\<\/\w*\>))/i;
    if (data) {
      console.log("tset", data.content.match(regex));
      if (data && data.content.match(regex) !== null) {
        note.replace(data && data.content);
      } else {
        note.insertText(data && data.content);
      }
    }
  };

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            {input && (
              <Card
                title={
                  (props.match.params.value !== "add" && "공지사항 상세") ||
                  (props.match.params.value === "add" && "공지사항 등록")
                }
                content={
                  <>
                    {props.match.params.value !== "add" && (
                      <FormInputs
                        ncols={["col-md-2", "col-md-5", "col-md-5"]}
                        properties={[
                          {
                            label: "번호",
                            type: "text",
                            bsClass: "form-control",
                            name: "id",
                            readOnly: true,
                            value: input.id || "",
                            style: { cursor: "initial" },
                          },
                          {
                            label: "등록일",
                            type: "text",
                            bsClass: "form-control",
                            name: "createdAt",
                            readOnly: true,
                            value:
                              Moment(input.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              ) || "",
                            style: { cursor: "initial" },
                          },
                          {
                            label: "수정일",
                            type: "text",
                            bsClass: "form-control",
                            name: "updatedAt",
                            readOnly: true,
                            value:
                              Moment(input.updatedAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              ) || "",
                            style: { cursor: "initial" },
                          },
                        ]}
                      />
                    )}

                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "제목",
                          type: "text",
                          bsClass: "form-control",
                          name: "title",
                          // readOnly: true,
                          onChange: (e) => handleInputChange(e),
                          value: input.title || "",
                          style: { cursor: "initial" },
                        },
                      ]}
                    />
                    <Row>
                      <Col md={12}>
                        <FormGroup controlId="formControlsTextarea">
                          <ControlLabel>내용</ControlLabel>
                          <ReactSummernote
                            options={{
                              lang: "ko-KR",
                              height: 350,
                              dialogsInBody: true,
                              toolbar: [
                                ["style", ["style"]],
                                ["font", ["bold", "underline", "clear"]],
                                ["fontname", ["fontname"]],
                                ["para", ["ul", "ol", "paragraph"]],
                                ["table", ["table"]],
                                ["insert", ["link", "picture", "video"]],
                                ["view", ["fullscreen", "codeview"]],
                              ],
                            }}
                            value={editorText}
                            onChange={onChange}
                            onImageUpload={onImageUpload}
                            onInit={onInit}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {props.match.params.value === "add" && (
                      <Button
                        bsStyle="info"
                        style={{ marginLeft: "1%" }}
                        pullRight
                        fill
                        onClick={(e) => onAdd(e)}
                      >
                        등록
                      </Button>
                    )}
                    {props.match.params.value !== "add" && (
                      <>
                        <Button
                          bsStyle="info"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={(e) => onSubmit(e)}
                        >
                          수정
                        </Button>
                        <Button
                          bsStyle="warning"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={(e) => onRemove(e)}
                        >
                          삭제
                        </Button>
                        <Button
                          bsStyle="success"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={(e) => history.goBack()}
                        >
                          목록
                        </Button>
                      </>
                    )}
                    <div className="clearfix" />
                  </>
                }
              />
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default NoticeDetail;
