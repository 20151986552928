import React, { useState, useEffect } from "react";
import { fetchData, addData, addFormData, editFormData,deleteData } from "modules/api.js";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import Pagination from "@material-ui/lab/Pagination";
import FormControl from "@material-ui/core/FormControl";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Moment from "moment";
import {
	style,
	buttonDivStyle,
	buttonStyle1,
	buttonStyle2,
	BootstrapInput,
	useStyles,
} from "./style.jsx";

function NoticeList(props) {
	const thArray = ["번호", "브랜드", "상품명","관리"];
	const classes = useStyles();
	const [dataList, setDataList] = useState();
	const [total, setTotal] = useState(1);
	const [totalItem, setTotalItem] = useState();
	const [searchData, setSearchData] = useState({
		searchWord: "",
		status: "",
	});
  const [input, setInput] = useState({
		id:'',
    brand: '',
    product: ''
  })
	const history = useHistory();
	const [page, setPage] = useState(1);

	const reload = (value) => {
		if (value === undefined || value === null) {
			value = page;
		}
		(async function anyNameFunction() {
			const result = await fetchData(
				// `admin/noticeList?page=${value}&searchWord=${searchData.searchWord}`
				`admin/brand?page=${value}&searchWord=${searchData.searchWord}`
			);
			console.log(result);
			setTotalItem(result.data.count);
			setTotal(Math.ceil(result.data.count / 10));
			setDataList(result.data.rows);
		})();
	};

	useEffect(() => {
		const auth = localStorage.getItem("auth") || "";
		if (auth === "" || auth === undefined || auth === null) {
			history.push("/login");
		}
		setPage(Number(props.match.params.value));
		reload(Number(props.match.params.value));
	}, []);

	const pageChange = (event, value) => {
		setPage(value);
		reload(value);
		history.push(`/admin/brandList/${value}`);
	};

	const searchChange = (event) => {
		if (window.event.keyCode === 13) {
			reload(1);
		}
		setSearchData({
			...searchData,
			[event.target.name]: event.target.value,
		});
	};

	const onSearch = () => {
		setPage(1);
		reload(1);
	};

  const onAdd = async () => {
    const body = input;
    if(body.brand === ""){
      alert("브랜드명을 입력해주세요");
    }
    if(body.product === ""){
      alert("상품명을 입력해주세요");
    }
    let res;
		if(body.id === ''){
			res = await addFormData('admin/brand',body);
		}else{
			res = await editFormData(`admin/brand/${body.id}`,body);
		}
    if(res.result){
			if(body.id === ''){
      	alert('등록되었습니다.')
			}else{
				alert('수정되었습니다.')
			}
      setPage(1);
		  reload(1);
    }else{
			if(body.id === ''){
      	alert('등록중 오류가 발생했습니다.')
			}else{
				alert('수정중 오류가 발생했습니다.')
			}
    }
  }

  const handleChange = (e) => {
    setInput({...input, [e.target.name]: e.target.value})
  }
	const handleEdit = (e) => {
		setInput({id:e.id, brand: e.brand, product: e.product})
	}

	const onDel = async (id) => {
		if(window.confirm("삭제하시겠습니까?")){
			const res = await deleteData('admin/brand',id);
    if(res.result){
      setPage(1);
		  reload(1);
			setInput({id: '', brand: '', product: ''})
    }else{
      alert('등록중 오류가 발생했습니다.')
    }
		}
	}
	const onReset = () => {
		setInput({id: '', brand: '', product: ''})
	}
	return (
		<div className="content">
			<Grid fluid>
				<Row>
					<Col md={9}>
						<Card
							title="브랜드 관리"
							ctTableFullWidth
							ctTableResponsive
							content={
								<div>
									<div style={buttonDivStyle}>
										<FormControl className={classes.margin}>
											<BootstrapInput
												id="demo-customized-textbox"
												name="searchWord"
												onChange={(e) => searchChange(e)}
												value={searchData.searchWord || ""}
												onKeyUp={(e) => searchChange(e)}
											/>
										</FormControl>
										<Button
											variant="outlined"
											style={buttonStyle1}
											onClick={(e) => onSearch(e)}
										>
											검색
										</Button>
										{/* <Button
											variant="outlined"
											style={buttonStyle2}
											onClick={(e) => history.push(`/admin/notice/add`)}
										>
											등록
										</Button> */}
									</div>
									<Table striped hover>
										<thead>
											<tr>
												{thArray.map((prop, index) => {
													return <th key={index}>{prop}</th>;
												})}
											</tr>
										</thead>
										<tbody>
											{dataList && dataList.length > 0 ? (
												dataList.map((data, index) => {
													// data.no = totalItem - (page - 1) * 20 - index;
													return (
														<tr
															key={data.id}
															
														>
															<td>{data.id || ""}</td>

															{/* <td>{data.breed.nameKr || ""}</td> */}
															<td>{data.brand}</td>
															{/* <td>{data.type}</td> */}
															<td>
                              {data.product}
															</td>
															<td>
																
																<Button
																	bsStyle="danger"
																	style={{ marginLeft: "1%" }}
																	pullRight
																	fill
																	onClick={(e) => onDel(data.id)}
																>
																	삭제
																</Button>
																<Button
																	bsStyle="info"
																	style={{ marginLeft: "1%" }}
																	pullRight
																	fill
																	onClick={(e) => handleEdit(data)}
																>
																	수정
																</Button>
															</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td colSpan="5" style={{ textAlign: "center" }}>
														검색결과가 없습니다
													</td>
												</tr>
											)}
										</tbody>
									</Table>
								</div>
							}
						/>
						<Pagination
							count={total}
							page={page}
							size="large"
							onChange={pageChange}
							variant="outlined"
							shape="rounded"
							style={style}
						/>
					</Col>
          <Col md={3}>
          <Card
								title=""
								content={
									
											
                  <Card
                    title="신규등록"
                    content={
                      <>
                        <FormInputs
                          ncols={["col-md-12"]}
                          properties={[
                            {
                              label: "브랜드명",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "브랜드명",
                              name: "brand",
                              value: input.brand || "",
                              onChange: (e) => handleChange(e),
                              style: { cursor: "initial" },
                            },
                          ]}
                        />
                      <FormInputs
                          ncols={["col-md-12"]}
                          properties={[
                            {
                              label: "상품명",
                              type: "text",
                              bsClass: "form-control",
                              placeholder: "상품명",
                              name: "product",
                              value: input.product || "",
                              onChange: (e) => handleChange(e),
                              style: { cursor: "initial" },
                            },
                          ]}
                        />
                        
												<Button
                          bsStyle="danger"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={(e) => onReset()}
                        >
                          취소
                        </Button>
                        <Button
                          bsStyle="info"
                          style={{ marginLeft: "1%" }}
                          pullRight
                          fill
                          onClick={(e) => onAdd()}
                        >
                          {input.id === '' ? '등록':'수정'}
                        </Button>
                      </>
                    }
                  />
										
								}
							/>
          </Col>
				</Row>
			</Grid>
		</div>
	);
}

export default NoticeList;
