import UserList from "views/users/UserList.jsx";
import UserDetail from "views/users/UserDetail.jsx";
import UserDropList from "views/dropUsers/UserList.jsx";

import NoticeList from "views/notice/NoticeList.jsx";
import NoticeDetail from "views/notice/NoticeDetail.jsx";

import FaqList from "views/faq/FaqList.jsx";
import FaqDetail from "views/faq/FaqDetail.jsx";
import Payment from "views/payment/Payment.jsx";
import AccountComplete from "views/payment/AccountComplete.jsx";
import SendReady from "views/payment/SendReady.jsx";
import SendComplete from "views/payment/SendComplete.jsx";
import BrandList from "views/brand/BrandList.jsx";

const dashboardRoutes = [
  {
    path: "/userList",
    component: UserList,
    layout: "/admin",
  },
  {
    path: `/user`,
    component: UserDetail,
    layout: "/admin",
  },
  {
    path: "/userDropList",
    component: UserDropList,
    layout: "/admin",
  },
  {
    path: `/noticeList`,
    component: NoticeList,
    layout: "/admin",
  },
  {
    path: `/notice`,
    component: NoticeDetail,
    layout: "/admin",
  },
  {
    path: `/faqList`,
    component: FaqList,
    layout: "/admin",
  },
  {
    path: `/faq`,
    component: FaqDetail,
    layout: "/admin",
  },
  {
    path: `/accountReady`,
    component: Payment,
    layout: "/admin",
  },
  {
    path: `/accountComplete`,
    component: AccountComplete,
    layout: "/admin",
  },
  {
    path: `/sendReady`,
    component: SendReady,
    layout: "/admin",
  },
  {
    path: `/sendComplete`,
    component: SendComplete,
    layout: "/admin",
  },
  {
    path: `/brandList`,
    component: BrandList,
    layout: "/admin",
  },
];

export default dashboardRoutes;
