import React, { useState, useEffect } from "react";
import { fetchData } from "modules/api.js";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import Pagination from "@material-ui/lab/Pagination";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import {
	style,
	buttonDivStyle,
	buttonStyle1,
	buttonStyle2,
	BootstrapInput,
	useStyles,
} from "./style.jsx";

function FaqList(props) {
	const thArray = ["번호", "제목", "등록일"];
	const classes = useStyles();
	const [dataList, setDataList] = useState();
	const [total, setTotal] = useState(1);
	const [totalItem, setTotalItem] = useState();
	const [searchData, setSearchData] = useState({
		searchWord: "",
		status: "",
	});
	const history = useHistory();
	const [page, setPage] = useState(1);

	const reload = (value) => {
		if (value === undefined || value === null) {
			value = page;
		}
		(async function anyNameFunction() {
			const result = await fetchData(
				// `admin/noticeList?page=${value}&searchWord=${searchData.searchWord}`
				`admin/faq?page=${value}&searchWord=${searchData.searchWord}`
			);
			console.log(result);
			// setTotalItem(result.total);
			// setTotal(Math.ceil(result.total / 10));
			setDataList(result.data.rows);
		})();
	};

	useEffect(() => {
		const auth = localStorage.getItem("auth") || "";
		if (auth === "" || auth === undefined || auth === null) {
			history.push("/login");
		}
		setPage(Number(props.match.params.value));
		reload(Number(props.match.params.value));
	}, []);

	const pageChange = (event, value) => {
		setPage(value);
		reload(value);
		history.push(`/admin/faqList/${value}`);
	};

	const searchChange = (event) => {
		if (window.event.keyCode === 13) {
			reload(1);
		}
		setSearchData({
			...searchData,
			[event.target.name]: event.target.value,
		});
	};

	const onSearch = () => {
		setPage(1);
		reload(1);
	};

	return (
		<div className="content">
			<Grid fluid>
				<Row>
					<Col md={12}>
						<Card
							title="FAQ 관리"
							ctTableFullWidth
							ctTableResponsive
							content={
								<div>
									<div style={buttonDivStyle}>
										<FormControl className={classes.margin}>
											<BootstrapInput
												id="demo-customized-textbox"
												name="searchWord"
												onChange={(e) => searchChange(e)}
												value={searchData.searchWord || ""}
												onKeyUp={(e) => searchChange(e)}
											/>
										</FormControl>
										<Button
											variant="outlined"
											style={buttonStyle1}
											onClick={(e) => onSearch(e)}
										>
											검색
										</Button>
										<Button
											variant="outlined"
											style={buttonStyle2}
											onClick={(e) => history.push(`/admin/faq/add`)}
										>
											등록
										</Button>
									</div>
									<Table striped hover>
										<thead>
											<tr>
												{thArray.map((prop, index) => {
													return <th key={index}>{prop}</th>;
												})}
											</tr>
										</thead>
										<tbody>
											{dataList && dataList.length > 0 ? (
												dataList.map((data, index) => {
													// data.no = totalItem - (page - 1) * 20 - index;
													return (
														<tr
															key={data.id}
															onClick={(e) =>
																history.push(`/admin/faq/${data.id}`)
															}
														>
															<td>{data.id || ""}</td>

															{/* <td>{data.breed.nameKr || ""}</td> */}
															<td>{data.title}</td>
															{/* <td>{data.type}</td> */}
															<td>
																{(data.createdAt &&
																	Moment(data.createdAt).format(
																		"YYYY-MM-DD HH:mm:ss"
																	)) ||
																	""}
															</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td colSpan="5" style={{ textAlign: "center" }}>
														검색결과가 없습니다
													</td>
												</tr>
											)}
										</tbody>
									</Table>
								</div>
							}
						/>
						<Pagination
							count={total}
							page={page}
							size="large"
							onChange={pageChange}
							variant="outlined"
							shape="rounded"
							style={style}
						/>
					</Col>
				</Row>
			</Grid>
		</div>
	);
}

export default FaqList;
