import React, { useState, useEffect } from "react";
import { fetchData } from "modules/api.js";
import { Grid, Row, Col, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import Pagination from "@material-ui/lab/Pagination";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import Moment from "moment";
import DatePicker from "react-datepicker";
import {
  style,
  buttonDivStyle,
  buttonStyle1,
  BootstrapInput,
  useStyles,
} from "./style.jsx";

function UserList(props) {
  const userThArray = [
    "번호",
    "정산일자",
    "판매자",
    "판매총건수",
    "판매가총액",
    "할인가총액",
    "실결제총액",
    "수익금총액",
    "정산번호",
    "정산상태"
  ];
  const classes = useStyles();
  const [dataList, setDataList] = useState();
  const [total, setTotal] = useState(1);
  const history = useHistory();
  const [searchData, setSearchData] = useState({
    startDate: "",
    endDate: "",
    searchWord: "",
    searchName: "",
  });
  const [page, setPage] = useState(1);
  const [totalItem, setTotalItem] = useState();

  const reload = (value) => {
    if (value === undefined || value === null) {
      value = page;
    }

    (async function anyNameFunction() {
      const result = await fetchData(
        `admin/orderList?page=${value}&searchWord=${searchData.searchWord}&status=1`
        // `admin/userList?page=${value}&level=user`
        // `admin/userList?page=${value}&level=user&searchWord=${searchData.searchWord}`
      );
      console.log(result);
      if (!result.result) {
        localStorage.removeItem("auth");
        history.push("/login");
      }
      setTotalItem(result.total);
      setTotal(Math.ceil(result.total / 10));
      // setDataList(result.data.rows);
      setDataList(result.data.rows);
    })();
  };

  useEffect(() => {
    const auth = localStorage.getItem("auth") || "";
    if (auth === "" || auth === undefined || auth === null) {
      history.push("/login");
    }
    console.log("재로딩");
    setPage(Number(props.match.params.value));
    reload(Number(props.match.params.value));
  }, []);

  const pageChange = (event, value) => {
    setPage(value);
    reload(value);
    history.push(`/admin/userList/${value}`);
  };

  const searchChange = (event) => {
    if (window.event.keyCode === 13) {
      reload(1);
    }
    setSearchData({
      ...searchData,
      [event.target.name]: event.target.value,
    });
  };

  const onSearch = () => {
    setPage(1);
    reload(1);
  };

  return (
    <div className="content">
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="지급 확정 내역"
              ctTableFullWidth
              ctTableResponsive
              content={
                <div>
                  {/* <ul style={{display:'flex', listStyle:'none', alignItems:'center'}}>
                    <li style={{width:'80px'}}>검색</li>
                    <li style={{marginLeft:'20px'}}>
                      <select name="searchType" style={{height: '40px', width:'150px', marginRight:'10px'}}>
                        <option value="">전체</option>
                        <option value="orderNumber">주문번호</option>
                      </select>
                      <BootstrapInput
                        id="demo-customized-textbox"
                        name="searchWord"
                        onChange={(e) => searchChange(e)}
                        value={searchData.searchWord || ""}
                        onKeyUp={(e) => searchChange(e)}
                      />
                    </li>
                  </ul> */}
                  {/* <ul style={{display:'flex', listStyle:'none', alignItems:'center'}}>
                    <li>회원등급</li>
                    <li style={{marginLeft:'20px'}}>
                      <select name="">

                      </select>
                    </li>
                  </ul> */}
                  <ul style={{display:'flex', listStyle:'none', alignItems:'center', }}>
                    <li style={{width:'80px'}}>기간</li>
                    <li style={{marginLeft:'20px', display:'flex'}}>
                      <div style={{marginRight:'5px'}}><DatePicker dateFormat="yyyy-MM-dd" selected={searchData.startDate} onChange={(date) => setSearchData({...searchData, startDate: date})} style={{height:'40px'}} /></div> 
                      ~
                      <div style={{marginLeft:'5px'}}><DatePicker dateFormat="yyyy-MM-dd" selected={searchData.endDate} onChange={(date) => setSearchData({...searchData, endDate: date})} style={{height:'40px'}} /></div>
                    </li>
                  </ul>
                  <ul style={{display:'flex', listStyle:'none', alignItems:'center'}}>
                    <li style={{width:'80px'}}>판매자이름</li>
                    <li style={{marginLeft:'20px'}}>
                      <BootstrapInput
                        id="demo-customized-textbox"
                        name="searchName"
                        onChange={(e) => searchChange(e)}
                        value={searchData.searchName || ""}
                        onKeyUp={(e) => searchChange(e)}
                      />
                    </li>
                  </ul>
                  <div style={{textAlign:'center', marginTop:'10px'}}>
                    <Button variant="outlined">검색</Button>
                  </div>
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              title="지급 확정 목록"
              ctTableFullWidth
              ctTableResponsive
              content={
                <div>
                  
                  <Table striped hover>
                    <thead>
                      <tr>
                        {userThArray.map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {dataList && dataList.length > 0 ? (
                        dataList.map((data, index) => {
                          // data.no = totalItem - (page - 1) * 20 - index;
                          // console.log(dataList.length);
                          const priceinfo = JSON.parse(data.initial_order_amount);
                          return (
                            <tr
                              key={index}
                              
                            >
                              <td>{index}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="10" style={{ textAlign: "center" }}>
                            검색결과가 없습니다
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              }
            />
            {/* <Pagination
              count={total}
              page={page}
              size="large"
              onChange={pageChange}
              variant="outlined"
              shape="rounded"
              style={style}
            /> */}
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

export default UserList;
